import React from "react"
import Style from "../styles/main.css"
import StyleResponsive from "../styles/main-responsive.css"
import HeaderDesktop from "../components/headerDesktop"
import HeaderMobile from "../components/headerMobile"
import Footer from "../components/footer"
import BannerPageComponent from "../components/sections/bannerPage"

export default function Hypnose() {
  return (
    <div className="irn-wrapper">
      <div className="irn-wrapper-inner-page">
        <HeaderDesktop />
        <HeaderMobile />
        <BannerPageComponent title="Hypnose"/>
      </div>
      <div className="irn-content-page">
          <div className="irn-content-page_block bold">
            <h3>Qu'est-ce que c'est ?</h3>
            <p>
            J’ai découvert l’hypnose par l’intermédiaire d’une amie qui avait suivi une formation à Orléans, centre de formations que j’ai rejoint pour me former à l’hypnose thérapeutique et qui répondait totalement à mes attentes et mes valeurs. 
            </p>
            <p>
            L’hypnose Ericksonnienne est un outil merveilleux qui m’a permis d’accéder à cette fantastique machine qu’est l’inconscient.
            </p>
            <p>Elle est encore trop souvent associée, dans la conscience collective, à l’hypnose de spectacle et c’est avec cet a priori que j’ai frappé à sa porte la première fois !!! </p>
            <p>Cette technique a été créée par Milton H. Erickson, psychiatre américain reconnu (1901-1980).<br/>Cet homme dyslexique, daltonien et atteint de poliomyélite a découvert l'hypnose au cours de ses études de médecine et mis en pratique l’autohypnose grâce à laquelle il sortira de la paralysie.</p>
            <p>Cette thérapie repose sur une sorte de collaboration entre le conscient et l’inconscient qui vont rechercher les solutions au problème donné.<br/>La personne est placée en état modifié de conscience. </p>
            <p>Pour ma part je propose deux séances mais peut-être ressentirez-vous le besoin d’une troisième consultation. </p>
            <p>L’hypnose Ericksonienne part du postulat que VOUS détenez en vous-même la solution à votre problème. C’est au patient de mettre à profit l’état hypnotique pour accéder à ses ressources intérieures. Les suggestions ne sont pas directives comme l’hypnose classique mais le vocabulaire est choisi très précisément pour que le consultant chemine vers son objectif via des suggestions indirectes.</p>
            <p>L’hypnose est une thérapie brève.</p>
            <p>Il y a plusieurs types d’hypnose et de méthodes possibles. Je trouve très intéressant de travailler avec le symbolisme. Il y a  différentes approches toutes aussi intéressantes qui doivent être adaptées à la sensibilité de la personne que l’on reçoit. Une séance en conversationnel est aussi efficace qu’une séance qui vous mènera  vers un état hypnothique plus profond.</p>
            <p>Chaque personne est unique et il en va de même pour chaque séance.</p>
            <p>C’est de cette façon que je travaille. « Deux séances en une seule » sur mesure !!!</p>
            <p>Alors convaincu ???  Au plaisir de vous accueillir.</p>
          </div>
      </div>
      <Footer />
    </div>
    )
}
